exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-detail-js": () => import("./../../../src/pages/blog/detail.js" /* webpackChunkName: "component---src-pages-blog-detail-js" */),
  "component---src-pages-blogs-custom-made-software-advantages-and-disadvantages-js": () => import("./../../../src/pages/blogs/custom-made-software-advantages-and-disadvantages.js" /* webpackChunkName: "component---src-pages-blogs-custom-made-software-advantages-and-disadvantages-js" */),
  "component---src-pages-blogs-custom-software-development-ultimate-guide-2023-js": () => import("./../../../src/pages/blogs/custom-software-development-ultimate-guide-2023.js" /* webpackChunkName: "component---src-pages-blogs-custom-software-development-ultimate-guide-2023-js" */),
  "component---src-pages-blogs-cutting-edge-technologies-for-custom-software-development-js": () => import("./../../../src/pages/blogs/cutting-edge-technologies-for-custom-software-development.js" /* webpackChunkName: "component---src-pages-blogs-cutting-edge-technologies-for-custom-software-development-js" */),
  "component---src-pages-blogs-how-a-product-design-sprint-will-help-you-build-a-great-product-js": () => import("./../../../src/pages/blogs/how-a-product-design-sprint-will-help-you-build-a-great-product.js" /* webpackChunkName: "component---src-pages-blogs-how-a-product-design-sprint-will-help-you-build-a-great-product-js" */),
  "component---src-pages-blogs-how-design-sprints-and-product-discovery-play-together-js": () => import("./../../../src/pages/blogs/how-design-sprints-and-product-discovery-play-together.js" /* webpackChunkName: "component---src-pages-blogs-how-design-sprints-and-product-discovery-play-together-js" */),
  "component---src-pages-blogs-how-important-is-it-to-create-a-product-strategy-js": () => import("./../../../src/pages/blogs/how-important-is-it-to-create-a-product-strategy.js" /* webpackChunkName: "component---src-pages-blogs-how-important-is-it-to-create-a-product-strategy-js" */),
  "component---src-pages-blogs-how-to-choose-the-best-custom-software-development-company-js": () => import("./../../../src/pages/blogs/how-to-choose-the-best-custom-software-development-company.js" /* webpackChunkName: "component---src-pages-blogs-how-to-choose-the-best-custom-software-development-company-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-blogs-product-discovery-framework-examples-js": () => import("./../../../src/pages/blogs/product-discovery-framework-examples.js" /* webpackChunkName: "component---src-pages-blogs-product-discovery-framework-examples-js" */),
  "component---src-pages-blogs-product-discovery-workshop-guide-js": () => import("./../../../src/pages/blogs/product-discovery-workshop-guide.js" /* webpackChunkName: "component---src-pages-blogs-product-discovery-workshop-guide-js" */),
  "component---src-pages-blogs-product-strategy-development-guide-js": () => import("./../../../src/pages/blogs/product-strategy-development-guide.js" /* webpackChunkName: "component---src-pages-blogs-product-strategy-development-guide-js" */),
  "component---src-pages-blogs-the-7-strategic-phases-of-the-product-planning-process-js": () => import("./../../../src/pages/blogs/the-7-strategic-phases-of-the-product-planning-process.js" /* webpackChunkName: "component---src-pages-blogs-the-7-strategic-phases-of-the-product-planning-process-js" */),
  "component---src-pages-blogs-the-straightforward-guide-to-product-strategy-js": () => import("./../../../src/pages/blogs/the-straightforward-guide-to-product-strategy.js" /* webpackChunkName: "component---src-pages-blogs-the-straightforward-guide-to-product-strategy-js" */),
  "component---src-pages-blogs-what-are-the-essentials-of-strategic-planning-for-a-product-js": () => import("./../../../src/pages/blogs/what-are-the-essentials-of-strategic-planning-for-a-product.js" /* webpackChunkName: "component---src-pages-blogs-what-are-the-essentials-of-strategic-planning-for-a-product-js" */),
  "component---src-pages-blogs-what-can-i-expect-from-a-custom-software-developer-js": () => import("./../../../src/pages/blogs/what-can-i-expect-from-a-custom-software-developer.js" /* webpackChunkName: "component---src-pages-blogs-what-can-i-expect-from-a-custom-software-developer-js" */),
  "component---src-pages-blogs-what-is-a-great-product-development-strategy-js": () => import("./../../../src/pages/blogs/what-is-a-great-product-development-strategy.js" /* webpackChunkName: "component---src-pages-blogs-what-is-a-great-product-development-strategy-js" */),
  "component---src-pages-blogs-what-is-agile-project-management-in-software-development-js": () => import("./../../../src/pages/blogs/what-is-agile-project-management-in-software-development.js" /* webpackChunkName: "component---src-pages-blogs-what-is-agile-project-management-in-software-development-js" */),
  "component---src-pages-blogs-what-is-custom-software-development-and-why-is-it-important-js": () => import("./../../../src/pages/blogs/what-is-custom-software-development-and-why-is-it-important.js" /* webpackChunkName: "component---src-pages-blogs-what-is-custom-software-development-and-why-is-it-important-js" */),
  "component---src-pages-blogs-what-is-the-average-cost-of-custom-software-development-js": () => import("./../../../src/pages/blogs/what-is-the-average-cost-of-custom-software-development.js" /* webpackChunkName: "component---src-pages-blogs-what-is-the-average-cost-of-custom-software-development-js" */),
  "component---src-pages-blogs-which-kind-of-industries-prefer-custom-made-software-js": () => import("./../../../src/pages/blogs/which-kind-of-industries-prefer-custom-made-software.js" /* webpackChunkName: "component---src-pages-blogs-which-kind-of-industries-prefer-custom-made-software-js" */),
  "component---src-pages-blogs-why-do-you-need-a-custom-software-solution-for-your-business-js": () => import("./../../../src/pages/blogs/why-do-you-need-a-custom-software-solution-for-your-business.js" /* webpackChunkName: "component---src-pages-blogs-why-do-you-need-a-custom-software-solution-for-your-business-js" */),
  "component---src-pages-career-details-[id]-index-js": () => import("./../../../src/pages/career-details/[id]/index.js" /* webpackChunkName: "component---src-pages-career-details-[id]-index-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-case-study-automotivesaas-js": () => import("./../../../src/pages/case-study/automotivesaas.js" /* webpackChunkName: "component---src-pages-case-study-automotivesaas-js" */),
  "component---src-pages-case-study-commercial-property-management-js": () => import("./../../../src/pages/case-study/commercial-property-management.js" /* webpackChunkName: "component---src-pages-case-study-commercial-property-management-js" */),
  "component---src-pages-case-study-energy-saving-platform-js": () => import("./../../../src/pages/case-study/energy-saving-platform.js" /* webpackChunkName: "component---src-pages-case-study-energy-saving-platform-js" */),
  "component---src-pages-case-study-experiential-events-fleet-management-platform-js": () => import("./../../../src/pages/case-study/experiential-events-&-fleet-management-platform.js" /* webpackChunkName: "component---src-pages-case-study-experiential-events-fleet-management-platform-js" */),
  "component---src-pages-case-study-financial-solution-for-gamers-js": () => import("./../../../src/pages/case-study/financial-solution-for-gamers.js" /* webpackChunkName: "component---src-pages-case-study-financial-solution-for-gamers-js" */),
  "component---src-pages-case-study-ohmconnect-2-js": () => import("./../../../src/pages/case-study/ohmconnect2.js" /* webpackChunkName: "component---src-pages-case-study-ohmconnect-2-js" */),
  "component---src-pages-case-study-productivity-solution-and-workflow-for-high-volume-photography-js": () => import("./../../../src/pages/case-study/productivity-solution-and-workflow-for-high-volume-photography.js" /* webpackChunkName: "component---src-pages-case-study-productivity-solution-and-workflow-for-high-volume-photography-js" */),
  "component---src-pages-case-study-shipment-management-js": () => import("./../../../src/pages/case-study/shipment-management.js" /* webpackChunkName: "component---src-pages-case-study-shipment-management-js" */),
  "component---src-pages-case-study-simplifying-math-for-all-students-js": () => import("./../../../src/pages/case-study/simplifying-math-for-all-students.js" /* webpackChunkName: "component---src-pages-case-study-simplifying-math-for-all-students-js" */),
  "component---src-pages-clutch-js": () => import("./../../../src/pages/clutch.js" /* webpackChunkName: "component---src-pages-clutch-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-custom-software-development-js": () => import("./../../../src/pages/custom-software-development.js" /* webpackChunkName: "component---src-pages-custom-software-development-js" */),
  "component---src-pages-data-engineering-js": () => import("./../../../src/pages/data-engineering.js" /* webpackChunkName: "component---src-pages-data-engineering-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-not-found-js": () => import("./../../../src/pages/not-found.js" /* webpackChunkName: "component---src-pages-not-found-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-design-js": () => import("./../../../src/pages/product-design.js" /* webpackChunkName: "component---src-pages-product-design-js" */),
  "component---src-pages-product-strategy-js": () => import("./../../../src/pages/product-strategy.js" /* webpackChunkName: "component---src-pages-product-strategy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-thank-you-careers-js": () => import("./../../../src/pages/thank-you-careers.js" /* webpackChunkName: "component---src-pages-thank-you-careers-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

